<template>
  <b-row class="match-height">
    <!-- 个人信息统计  -->
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <el-col
            :span="8"
            class="font-medium-1 mt-50"
          >
            <span class="title">个人同意统计</span>
          </el-col>
          <div class="d-flex align-items-center">
            <el-date-picker
              v-model="time"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              placement="bottom-start"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getUserDataChart"
            />
          </div>
        </b-card-header>

        <b-card-body v-loading="totalLineLoading">
          <div v-show="boxs.length>0">
            <b-row class="match-height">
              <b-col
                v-for="(item,index) in boxs"
                :key="index"
                xl="2"
                md="4"
                sm="6"
              >
                <statistic-card-vertical
                  :color="lawsColor[index]"
                  icon="FileTextIcon"
                  :statistic="item.count"
                  :statistic-title="item.typeName"
                />
              </b-col>
            </b-row>
          </div>
          <el-empty
            v-show="boxs.length == 0"
            description="暂无数据"
            size="100"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <!-- 个人信息趋势  -->
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <el-col
            :span="8"
            class="font-medium-1 mt-50"
          >
            <span class="title">同意数量趋势</span>
          </el-col>
          <div class="d-flex align-items-center">
            <el-date-picker
              v-model="time2"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              placement="bottom-start"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getAgreeWithTrend"
            />
          </div>
        </b-card-header>

        <b-card-body v-loading="totalLineLoading">
          <div style="position: relative">
            <div
              id="lineChart"
              :style="option.xAxis[0].data.length>0 ? {'opacity' : 1} : {'opacity' : 0}"
              style="width: 100%;height: 300px;margin-bottom: 20px"
            />
            <el-empty
              v-show="option.xAxis[0].data.length==0"
              class="chart-empty"
              description="暂无数据"
            />
          </div>
        </b-card-body>
      </b-card>
    </b-col>

    <!-- 渠道（采集点）获得的同意数  -->
    <b-col cols="12">
      <b-card no-body>
        <b-card-header>
          <el-col
            :span="8"
            class="font-medium-1 mt-50"
          >
            <span class="title">渠道（采集点）同意数Top10</span>
          </el-col>
          <div class="d-flex align-items-center">
            <el-date-picker
              v-model="time3"
              type="daterange"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="getChannelUserDataCount"
            />
          </div>
        </b-card-header>

        <b-card-body v-loading="totalLineLoading">
          <div v-show="totalList.length>0">
            <b-row class="match-height">
              <b-col
                v-for="(item,index) in totalList"
                :key="index"
                xl="2"
                md="4"
                sm="6"
              >
                <b-card class="text-center">
                  <h4>{{ item.type }}</h4>
                  <div class="truncate">
                    <h2 class="mb20 mt20 font-weight-bolder">
                      {{ item.total }}
                    </h2>
                    <span>{{ item.name }}</span>
                  </div>
                </b-card>
              </b-col>
            </b-row>
          </div>
          <el-empty
            v-show="totalList.length == 0"
            description="暂无数据"
            size="100"
          />
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>

</template>

<script>
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import * as echarts from 'echarts'
import { GetChannelUserDataCount, GetUserDataChart, GetAgreeWithTrend } from '@/api/cpm/cpm'
import Moment from 'moment'

export default {
  name: 'CPMList',
  components: {
    StatisticCardVertical,
  },
  data() {
    const beginTime = Moment().subtract(6, 'months').format('YYYY-MM-DD')
    const endTime = Moment().format('YYYY-MM-DD')
    return {
      rangePicker: ['2022-07', '2023-01'],
      loading: false,
      totalLineLoading: false,
      loadingStyle: {
        text: '数据正在加载...',
        textColor: '#fff',
        color: '#0d40b2',
        maskColor: 'rgba(255, 255, 255, 0)',
        zlevel: 0,

        // 字体大小。从 `v4.8.0` 开始支持。
        fontSize: 12,
        // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
        showSpinner: true,
        // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
        spinnerRadius: 10,
        // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
        lineWidth: 5,
        // 字体粗细。从 `v5.0.1` 开始支持。
        fontWeight: 'normal',
        // 字体风格。从 `v5.0.1` 开始支持。
        fontStyle: 'normal',
        // 字体系列。从 `v5.0.1` 开始支持。
        fontFamily: 'sans-serif',
      },
      cureentTime: '',
      time: [beginTime, endTime],
      time2: [beginTime, endTime],
      time3: [beginTime, endTime],
      boxs: [
        { count: 0, typeName: '个人信息数量' },
        { count: 0, typeName: '同意数' },
        { count: 0, typeName: '不同意数' },
      ],
      totalList: [
      ],
      lawsColor: ['warning', 'info', 'danger'],
      total: 0,
      listQuery: {
        page: 1,
        pageSize: 10,
        SearchStr: '',
        channelType: '',
      },
      chart: null,
      option: {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '15%',
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              show: true,
            },
            axisLabel: {
              color: '#5e5873',
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: [],
          },
        ],

        yAxis: [
          {
            type: 'value',
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.1)',
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              margin: 20,
              textStyle: {
                color: '#5e5873',
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '授权同意',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            lineStyle: {
              normal: {
                color: '#00ca95',
              },
            },
            label: {
              show: false,
            },
            itemStyle: {
              color: '#00ca95',
              borderColor: '#00ca95',
              borderWidth: 3,
            },
            tooltip: {
              show: true,
            },
            data: [],
          },
          {
            name: '取消同意',
            type: 'line',
            showAllSymbol: true,
            symbol: 'circle',
            label: {
              show: false,
            },
            itemStyle: {
              color: '#6c50f3',
              borderColor: '#6c50f3',
              borderWidth: 3,
            },
            tooltip: {
              show: false,
            },
            data: [],
          },
        ],
      },
    }
  },
  created() {
    this.getChannelUserDataCount()
  },
  mounted() {
    this.getUserDataChart()
    this.getAgreeWithTrend()
  },
  methods: {
    getAgreeWithTrend() {
      const params = {
        beginTime: '',
        endTime: '',
      }
      if (this.time2) {
        params.beginTime = this.time2[0]
        params.endTime = this.time2[1]
      }
      if (this.chart === null) {
        this.chart = echarts.init(document.getElementById('lineChart'))
      }

      this.option.xAxis[0].data = []
      this.option.series[0].data = []
      this.option.series[1].data = []
      this.loading = true
      this.chart.showLoading(this.loadingStyle)
      GetAgreeWithTrend(params).then(res => {
        this.chart.hideLoading()
        this.loading = false
        const resData = res.data
        resData.data.forEach(r => {
          this.option.xAxis[0].data.push(r.clickTime)
          this.option.series[0].data.push(r.agreeCount)
          this.option.series[1].data.push(r.NotAgreeCount)
        })
        this.chart.setOption(this.option)
      })
    },
    getChannelUserDataCount() {
      const params = {
        beginTime: '',
        endTime: '',
      }
      if (this.time3) {
        params.beginTime = this.time3[0]
        params.endTime = this.time3[1]
      }
      this.totalList = []
      GetChannelUserDataCount(params).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          const channelList = resData.data
          channelList.forEach(c => {
            this.totalList.push({
              type: c.channelName,
              name: '通过网站的同意数',
              total: c.userDataCount,
            })
          })
        }
      })
    },
    getUserDataChart() {
      const params = {
        beginTime: '',
        endTime: '',
      }
      if (this.time) {
        params.beginTime = this.time[0]
        params.endTime = this.time[1]
      }
      GetUserDataChart(params).then(res => {
        this.chart.hideLoading()
        this.loading = false
        const resData = res.data
        this.boxs[0].count = resData.data.total
        this.boxs[1].count = resData.data.totalAgreeCount
        this.boxs[2].count = resData.data.totalNotAgreeCount
      })
    },
  },
}
</script>
<style>
.chart-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

/*title*/
.title {
  border-left: solid 6px #0d40b2;
  padding-left: 8px;
  margin-bottom: 2px;
}
</style>
