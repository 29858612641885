import { downloadByGet, get, post } from '@/libs/axios'

// 查询cookie列表
export const ListCookieInfo = params => post('/cpm/ListCookieInfo', params)
export const ListWebsite = params => post('/cpm/ListWebsite', params)
export const ListCookieTheme = params => post('/cpm/ListCookieTheme', params)
// 新建cookie
export const CreateCookie = params => post('/cpm/CreateCookie', params)
export const DelWebsite = params => post('/cpm/DelWebsite', params)
export const DelCookieTheme = params => post('/cpm/DelCookieTheme', params)
export const DelCookie = params => post('/cpm/DelCookie', params)

export const ListCookieType = () => get('/cpm/purpose/getOption')
export const GetCookieOption = () => get('/cpm/cookie/getOption')
export const purposePage = params => post('/cpm/purpose/page',params)
export const PageChannel = params => post('/cpm/PageChannel',params)
export const DelChannel = params => post('/cpm/DelChannel',params)
export const UpdateChannelStatus = params => post('/cpm/UpdateChannelStatus',params)
export const GetThemeById = id => get(`/cpm/GetThemeById?id=${id}`)
export const GetChannelInfo = id => get(`/cpm/GetChannelInfo?id=${id}`)
export const cookieDeployDoc = id => get(`/cpm/cookieDeployDoc?id=${id}`)

export const GetChannelUserDataCount = (params) => post(`/cpm/userDate/GetChannelUserDataCount`,params)
export const GetUserDataChart = params => post(`/cpm/userDate/GetUserDataChart`,params)
export const GetAgreeWithTrend = params => post(`/cpm/userDate/GetAgreeWithTrend`,params)
export const saveOrUpdateCoookie = params => {
  const submitUrl = params.id ? '/cpm/UpdateCookie' : '/cpm/CreateCookie'
  return post(submitUrl, params)
}
export const saveOrUpdateCookieTheme = (params) => {
  const submitUrl = params.id ? '/cpm/UpdateCookieTheme' : '/cpm/CreateCookieTheme'
  return post(submitUrl, params)
}
//
export const saveOrUpdateWebsite = params => {
  const submitUrl = params.id ? '/cpm/UpdateWebsite' : '/cpm/CreateWebsite'
  return post(submitUrl, params)
}
//
export const saveOrUpdateChannel = params => {
  const submitUrl = params.id ? '/cpm/UpdateChannel' : '/cpm/CreateChannel'
  return post(submitUrl, params)
}
export const GetChannelOption = () => get('/cpm/GetChannelOption')
export const GetThemeOption = () => get('/cpm/GetThemeOption')
export const GetWebsiteById = id => get(`/cpm/GetWebsiteById?id=${id}`)
export const DownloadDeployDoc = id => downloadByGet('/cpm/cookieDeployDoc', { id })
